import Swiper, { Navigation } from 'swiper';

const swiper = new Swiper('.HomeSlider__swiper', {
    modules: [Navigation],
    slidesPerView: 'auto',
    navigation: {
        nextEl: '.HomeSlider__arrow--right',
        prevEl: '.HomeSlider__arrow--left',
    },
});

import $ from 'jquery';

const buttons = $('.Tabs__buttons button');

const toggleTab = (ev) => {
    const tab = $(ev.currentTarget).attr('id');
    $('[data-tab-id').removeClass('active');
    $(`[data-tab-id="${tab}"]`).addClass('active');

    buttons.removeClass('active');
    $(ev.currentTarget).addClass('active');
};

buttons.on('click', toggleTab);

import Swiper, { EffectFade, Lazy, Thumbs, Autoplay } from 'swiper';

const thumbnailsSlider = new Swiper('.single-product-slider-thumbnails', {
    modules: [Autoplay],
    slidesPerView: 5,
    watchSlidesVisibility: true,
    spaceBetween: 8,
    autoplay: {
        delay: 5000,
    },
});

const singleProductSlider = new Swiper('.single-product-slider', {
    modules: [EffectFade, Lazy, Thumbs],
    effect: 'slide',
    // fadeEffect: {
    //     crossFade: true,
    // },
    thumbs: {
        swiper: thumbnailsSlider,
    },
    autoHeight: true,
    loop: true,
    preloadImages: true,
    watchSlidesVisibility: true,
    lazy: {
        preloaderClass: 'single-product-slider-preloader',
    },
});

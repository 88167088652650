const ACTIVE_CLASS_MENU = 'Header__menu--active';
const ACTIVE_CLASS_HAMBURGER = 'Hamburger--open';

const hamburgerBtn = document.getElementById('toggle');
const navigation = document.querySelector('.Header__menu');

const closeAllNavigations = () => {
    document.querySelectorAll('.Navigation ul.open').forEach((nav) => nav.classList.remove('open'));
};

const query = window.matchMedia('(max-width: 1024px)');

const handleSetOverflow = () => {
    if (query.matches) {
        if (!navigation.classList.contains(ACTIVE_CLASS_MENU)) {
            document.documentElement.style.overflowY = 'auto';
            closeAllNavigations();
        } else {
            document.documentElement.style.overflowY = 'hidden';
        }
    } else {
        document.documentElement.style.overflowY = 'auto';
    }
};

const toggleMenu = (ev) => {
    ev.currentTarget.classList.toggle(ACTIVE_CLASS_HAMBURGER);
    navigation?.classList.toggle(ACTIVE_CLASS_MENU);

    handleSetOverflow();
};

hamburgerBtn?.addEventListener('click', toggleMenu);

query.addEventListener('change', handleSetOverflow);
